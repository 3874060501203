<script setup>
import { computed } from "vue";
import { getPermitForBusiness } from "@/utils/functions";
import ActionsContainer from "../utils/ActionsContainer.vue";
import ActionButton from "../utils/ActionButton.vue";
import { actions } from "@/utils/constants";
import Table from "../utils/Table.vue";

const props = defineProps({
  businesses: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const businessesNotEmpty = computed(() => {
  return props.businesses !== null && props.businesses.length > 0;
});

function getSbpNumber(business) {
  const permit = getPermitForBusiness(business);
  const sbpNumber = permit?.sbp_number;
  return sbpNumber;
}

function getBusinessStatus(business) {
  const permit = getPermitForBusiness(business);
  const businessStatus = permit?.permit_status;
  return businessStatus;
}

function getBusinessActivity(business) {
  const businessActivity = business.business_activity;
  const businessActivityNotEmpty =
    businessActivity !== null && businessActivity !== undefined;
  if (businessActivityNotEmpty) {
    return businessActivity.activity_name;
  }
  return "N/A";
}

function getBusinessTown(business) {
  // return getTownFromSubcountyAndWard({
  //     subcountyId: business.sub_county_id,
  //     wardId: business.ward_id,
  //     townId: business.town_id
  // }) ?? 'N/A'
  return business?.town?.town_name ?? "N/A";
}

function getColorForBusinessStatus(business) {
  const lowerCaseStatus = getBusinessStatus(business).toLowerCase();
  if (lowerCaseStatus === "approved") {
    return "success";
  } else if (lowerCaseStatus === "expired") {
    return "error";
  } else if (lowerCaseStatus === "pending") {
    return "purple";
  }
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <div :style="{ position: 'relative' }">
          <v-progress-linear v-if="loading" indeterminate class="mb-4" />

          <Table
            :headers="[
              { text: 'SBP Number', value: 'sbp_number' },
              { text: 'Business Name', value: 'business_name' },
              { text: 'Business Activity', value: 'business_activity' },
              { text: 'Town', value: 'town' },
              { text: 'Status', value: 'status' },
              { text: 'View', value: 'view', action: true },
              { text: 'Renew', value: 'renew', action: true },
            ]"
            :items="businesses"
            headClasses="primary white--text"
            :loading="loading"
            emptyText="No Businesses Found"
          >
            <template #itemTitle="{ item: business }">
              {{ business.business_name }}
            </template>

            <template #item.sbp_number="{ item: business }">
              {{ getSbpNumber(business) }}
            </template>

            <template #item.business_activity="{ item: business }">
              {{ getBusinessActivity(business) }}
            </template>

            <template #item.town="{ item: business }">
              {{ getBusinessTown(business) }}
            </template>

            <template #item.status="{ item: business }">
              <v-chip
                :color="getColorForBusinessStatus(business)"
                text-color="white"
                small
              >
              {{ getBusinessStatus(business) }}
              </v-chip>
              <!-- <span :class="[getColorForBusinessStatus(business), 'white--text']">
                {{ getBusinessStatus(business) }}
              </span> -->
            </template>

            <template #item.view="{ item: business }">
              <ActionButton
                :action="actions.view"
                inTable
                @click="$emit('viewBusiness', business)"
              />
            </template>

            <template #item.renew="{ item: business }">
              <ActionButton
                :action="actions.renewPermit"
                inTable
                @click="$emit('renewPermit', business)"
              />
            </template>
          </Table>

          <v-simple-table v-if="false">
            <template #default>
              <thead>
                <tr class="primary white--text">
                  <th class="text-left white--text">SBP Number</th>
                  <th class="text-left white--text">Business Name</th>
                  <th class="text-left white--text">Business Activity</th>
                  <th class="text-left white--text">Town</th>
                  <th class="text-left white--text">Status</th>
                  <th class="text-right white--text">Actions</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="businessesNotEmpty">
                  <tr v-for="business in businesses" :key="business.id">
                    <td>
                      {{ getSbpNumber(business) }}
                    </td>
                    <td>
                      {{ business.business_name }}
                    </td>
                    <td>
                      {{ getBusinessActivity(business) }}
                    </td>
                    <td>
                      {{ getBusinessTown(business) }}
                    </td>
                    <td>
                      {{ getBusinessStatus(business) }}
                    </td>
                    <td class="text-right">
                      <!-- <v-tooltip top>
                                                <template #activator="{ on, attrs }">
                                                    <v-btn icon color="primary" @click="$emit('viewBusiness', business)"
                                                        v-bind="attrs" v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
                                                </template>
                                                <span>View</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template #activator="{ on, attrs }">
                                                    <v-btn icon color="purple" @click="$emit('renewPermit', business)"
                                                        v-bind="attrs" v-on="on"><v-icon>mdi-file</v-icon></v-btn>
                                                </template>
                                                <span>Renew Permit</span>
                                            </v-tooltip> -->
                      <ActionsContainer>
                        <ActionButton
                          :action="actions.view"
                          inTable
                          @click="$emit('viewBusiness', business)"
                        />
                        <ActionButton
                          :action="actions.renewPermit"
                          inTable
                          @click="$emit('renewPermit', business)"
                        />
                      </ActionsContainer>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td class="text-center" colspan="6">
                    {{ props.loading ? "Loading" : "No businesses found" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
