<script setup>
import { useViewBusiness } from "./view_business_business_logic";
import ViewBusinessesTable from "./ViewBusinessesTable.vue";
import ViewBusiness from "./ViewBusiness.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref } from "vue";
import {
  snackbarData,
  resetSnackbarOnMount,
} from "@/components/utils/snackbar/logic";
import { getCurrentBusinessPermit } from "@/utils/functions";
import { useRouter } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";

resetSnackbarOnMount();

const { router } = useRouter();

const {
  viewBusinessData,
  fetchBusinesses,
  fetchingBusinesses,
  fetchedBusinessesNotEmpty,
  paginationComponentLength,
} = useViewBusiness();

const pages = {
  VIEW_BUSINESSES_TABLE: 0,
  VIEW_BUSINESS: 1,
};
const currentPage = ref(pages.VIEW_BUSINESSES_TABLE);

const businessUnderView = ref(null);
const businessUnderViewNotEmpty = computed(() => {
  return businessUnderView.value !== null;
});

function moveToViewBusinessPage(business) {
  businessUnderView.value = business;
  currentPage.value = pages.VIEW_BUSINESS;
}

function moveToViewBusinessesTable() {
  currentPage.value = pages.VIEW_BUSINESSES_TABLE;
}

function checkCurrentPermitRenewable(business) {
  const currentPermit = getCurrentBusinessPermit(business);
  
  const permitPending = currentPermit.permit_status === "Pending";
  if (permitPending) {
    snackbarData.text =
      "This business has a pending process";
    snackbarData.color = "warning";
    snackbarData.open = true;
    return false;
  }

  const permitValidTo = new Date(currentPermit.validity_to);
  const now = new Date();
  const permitValid = permitValidTo > now;
  if (permitValid) {
    snackbarData.text = "This business has a valid permit";
    snackbarData.color = "warning";
    snackbarData.open = true;
    return false;
  }
  const permitNotExpired = currentPermit.permit_status !== "Expired";
  if (permitNotExpired) {
    snackbarData.text = "This permit has not expired";
    snackbarData.color = "warning";
    snackbarData.open = true;
    return false;
  }
  return true;
}

function goToRenewSbp(business) {
  router.value.push({
    ...routes.renewSbp,
    params: {
      business,
    },
  });
}

function renewPermit(business) {
  const permitRenewable = checkCurrentPermitRenewable(business);
  if (permitRenewable) {
    goToRenewSbp(business);
  }
}

async function onPaginationClicked(newPage) {
  viewBusinessData.paginationData.currentPage = newPage;
  await fetchBusinesses();
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage" touchless>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              Businesses
            </div>
          </v-col>
        </v-row>
        <v-row justify-md="center" dense>
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field
                class="background text-field"
                outlined
                dense
                v-model="viewBusinessData.searchModel"
                clearable
              />
            </LabelledField>
          </v-col>
        </v-row>
        <div :style="{ position: 'relative' }">
          <v-row class="mt-3">
            <v-col cols="12">
              <ViewBusinessesTable
                :businesses="viewBusinessData.fetchedBusinesses"
                :loading="fetchingBusinesses"
                @viewBusiness="moveToViewBusinessPage"
                @renewPermit="renewPermit"
              />
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <v-pagination
                v-if="
                  paginationComponentLength !== null &&
                  fetchedBusinessesNotEmpty
                "
                :length="paginationComponentLength"
                total-visible="10"
                v-model="viewBusinessData.paginationData.currentPage"
                @input="onPaginationClicked"
                circle
              />
            </v-col>
          </v-row>
          <v-overlay
            v-if="fetchingBusinesses"
            absolute
            opacity="0.5"
            color="white"
          />
        </div>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <ViewBusiness
              :business="businessUnderView"
              showGoBack
              @goBackClicked="moveToViewBusinessesTable"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
